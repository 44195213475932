<template>
  <CyKpiWidgetsWidget
    v-bind="$attrs"
    :kpi="kpi"
    v-on="$listeners">
    <div
      v-if="!_.isEmpty(kpi.data_set)"
      class="code-coverage">
      <ECharts
        class="code-coverage__chart"
        theme="cycloid"
        autoresize
        :option="options"/>

      <ul class="code-coverage__aside list-style-none">
        <li class="code-coverage__item">
          <CyKpiWidgetsNumber
            :value="recent"
            :prev-value="old"
            :format="formatPercentage"
            :label="$t('kpis.codeCoverage')"/>
        </li>
      </ul>
    </div>
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot"/>
      </template>
    </template>
  </CyKpiWidgetsWidget>
</template>

<script>
import CyKpiWidgetsWidget from '@/components/kpi-widgets/widget'
import CyKpiWidgetsNumber from '@/components/kpi-widgets/number'

export default {
  name: 'CyKpiWidgetsCodeCoverage',
  components: {
    CyKpiWidgetsWidget,
    CyKpiWidgetsNumber,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    recent: 0,
    old: 0,
  }),
  computed: {
    chartData () {
      return [{
        name: 'covered',
        value: this.recent,
      }, {
        name: 'uncovered',
        value: 100 - this.recent,
      }]
    },
    options () {
      return {
        color: ['#51AF45', '#C4C4C4'],
        series: [
          {
            data: this.chartData,
            type: 'pie',
            silent: true,
            top: 16,
            right: 16,
            bottom: 16,
            left: 16,
            radius: ['70%', '100%'],
            label: {
              show: false,
            },
          },
        ],
      }
    },
  },
  watch: {
    'kpi.data_set': {
      immediate: true,
      handler: function (dataSet) {
        if (_.isEmpty(dataSet)) return
        this.setDataFromSource(dataSet)
      },
    },
  },
  methods: {
    setDataFromSource ([keys, ...rows]) {
      const formattedRows = _.map(rows, (row) => _.zipObject(keys, row))
      this.old = _.minBy(formattedRows, 'date').coverage
      this.recent = _.maxBy(formattedRows, 'date').coverage
    },
    formatPercentage (value) {
      return `${value.toFixed(2)}%`
    },
  },
  i18n: {
    messages: {
      en: {
        coveredLines: 'Covered lines',
        totalLines: 'Total lines',
      },
      es: {
        coveredLines: 'Líneas cubiertas',
        totalLines: 'Líneas totales',
      },
      fr: {
        coveredLines: 'Lignes couvertes',
        totalLines: 'Lignes totales',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.code-coverage {
  display: flex;

  &__chart {
    flex: 1 1 244px;
    min-width: 0;
    height: 244px;
  }

  &__aside {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin-left: 24px;
  }

  &__item {
    & + & {
      margin-top: 16px;
    }
  }
}
</style>
