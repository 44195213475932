<template>
  <CyKpiWidgetsWidget
    v-bind="$attrs"
    :kpi="kpi"
    v-on="$listeners">
    <div
      v-if="!_.isEmpty(kpi.data_set)"
      class="time-to-release">
      <CyKpiWidgetsNumber
        class="mb-3"
        :value="averageTotalDuration"
        :format="formatDuration"
        :label="$t('averageTotalTime')"/>

      <ul class="time-to-release__legend cy-scrollbars">
        <li
          v-for="env in kpi.config.envs"
          :key="env">
          <div class="time-to-release__header">
            <span
              class="time-to-release__marker"
              :style="{ backgroundColor: getEnvColor(env) }"/>
            <span class="time-to-release__label">{{ env }}</span>
          </div>

          <div class="time-to-release__average">
            {{ averageDurationByEnv[env] ? formatDuration(averageDurationByEnv[env]) : '--' }}
          </div>
        </li>
      </ul>

      <ECharts
        class="time-to-release__chart"
        theme="cycloid"
        autoresize
        :option="options"/>
    </div>
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot"/>
      </template>
    </template>
  </CyKpiWidgetsWidget>
</template>

<script>
import CyKpiWidgetsWidget from '@/components/kpi-widgets/widget'
import CyKpiWidgetsNumber from '@/components/kpi-widgets/number'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import 'moment-duration-format'

const COLORS_BY_ENV = {
  dev: '#3b4f6b',
  staging: '#f08b02',
  preprod: '#f08b02',
  demo: '#1c9797',
  production: '#1c9797',
  prod: '#1c9797',
  default: '#582026',
}

export default {
  name: 'CyKpiWidgetsTimeToRelease',
  components: {
    CyKpiWidgetsNumber,
    CyKpiWidgetsWidget,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
    },
  },
  computed: {
    options () {
      return {
        series: [
          ...this.series,
          {
            type: 'bar',
            stack: 'stack',
            name: 'released',
            markPoint: {
              symbol: 'diamond',
              symbolSize: 16,
              itemStyle: {
                color: '#FFFFFF',
                borderWidth: 2,
              },
              data: this.markpointsData,
            },
          },
        ],
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: this.showDataZoom ? 20 : 10,
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          axisLabel: { show: false },
        },
        yAxis: {
          type: 'category',
          data: this.versions,
          inverse: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            z: 0,
          },
          formatter: this.formatTooltip,
        },
        dataZoom: this.showDataZoom
          ? [{
              type: 'slider',
              zoomLock: true,
              show: true,
              right: 0,
              width: 10,
              handleSize: 0,
              showDataShadow: false,
              showDetail: false,
              orient: 'vertical',
              maxValueSpan: 5,
            },
            {
              type: 'inside',
              id: 'insideY',
              yAxisIndex: 0,
              zoomOnMouseWheel: false,
              moveOnMouseMove: true,
              moveOnMouseWheel: true,
            }]
          : [],
      }
    },
    formattedEvents () {
      const [keys, ...rows] = this.kpi.data_set || []
      return _.chain(rows)
        .map((row) => _.zipObject(keys, row))
        .value()
    },
    eventsByVersion () {
      return _.groupBy(this.formattedEvents, 'version')
    },
    versions () {
      return _.chain(this.eventsByVersion)
        .keys()
        .sort((versionA, versionB) => this.getVersionStartDate(versionB) - this.getVersionStartDate(versionA))
        .value()
    },
    series () {
      return _.chain(this.formattedEvents)
        .groupBy('env')
        .map((events, env) => {
          return {
            type: 'bar',
            name: env,
            stack: 'stack',
            data: this.versions.map((version) => _.find(events, { version })?.duration || null),
            barWidth: 8,
            itemStyle: {
              borderRadius: 0,
              color: this.getEnvColor(env),
            },
          }
        })
        .value()
    },
    markpointsData () {
      const finalEnv = _.last(this.kpi.config.envs)
      const events = this.formattedEvents
      const implicitReleaseEvents = _.chain(this.eventsByVersion)
        .pickBy((events) => _.every(events, 'released') && !_.some(events, ({ env }) => env === finalEnv))
        .keys()
        .map((version) => ({ version, env: finalEnv }))
        .value()

      return _.map([...events, ...implicitReleaseEvents], ({ env, version, date }) => ({
        coord: [
          _.chain(this.eventsByVersion[version])
            .filter((event) => !date || event.date < date)
            .sumBy('duration')
            .value(),
          version,
        ],
        itemStyle: {
          borderColor: this.getEnvColor(env),
        },
      }))
    },
    showDataZoom () {
      return this.versions.length >= 6
    },
    durationByVersion () {
      return _.mapValues(this.eventsByVersion, (version) => _.reduce(version, (sum, { duration }) => sum + duration, 0))
    },
    maxVersionDuration () {
      return _.max(_.values(this.durationByVersion))
    },
    averageDurationByEnv () {
      return _.chain(this.formattedEvents)
        .filter('duration')
        .groupBy('env')
        .mapValues((events) => _.round(_.meanBy(events, 'duration')))
        .value()
    },
    averageTotalDuration () {
      return _.chain(this.averageDurationByEnv)
        .values()
        .sum()
        .value()
    },
  },
  methods: {
    getEnvColor (env) {
      return _.get(COLORS_BY_ENV, env, COLORS_BY_ENV.default)
    },
    getVersionStartDate (version) {
      return _.minBy(this.eventsByVersion[version], 'date').date
    },
    formatDuration (duration) {
      let format = 'd[d] h[h]' // Display duration in days by default
      if (this.maxVersionDuration < 36000000) format = 'm[m] s[s]' // when max duration is smaller than 10h, we display duration in minutes
      else if (this.maxVersionDuration < 864000000) format = 'h[h] m[m]' // when max duration is smaller than 10d, we display duration in hours
      return moment.duration(duration).format(format) // eslint-disable-line you-dont-need-momentjs/format
    },
    formatTooltip (params) {
      const version = params[0].axisValue
      const values = params.map(({ seriesName, marker, value }) => `${marker} ${seriesName}: ${value ? this.formatDuration(value) : '--'}`)
      const startDate = _.chain(this.eventsByVersion[version])
        .map('date')
        .min()
        .value()
      const isReleased = _.some(this.eventsByVersion[version], 'released')
      const formattedStartDate = moment(startDate).format('dd, MMM Do YYYY') // eslint-disable-line you-dont-need-momentjs/format
      return `<b>${version}</b><br>
Started on: ${formattedStartDate}<br>
${isReleased ? 'Released<br>' : ''}
<br>
${values.join('<br>')}<br>
Total: ${this.formatDuration(this.durationByVersion[version])}`
    },
  },
  i18n: {
    messages: {
      en: {
        averageTotalTime: 'Average total time',
      },
      es: {
        averageTotalTime: 'Tiempo total medio',
      },
      fr: {
        averageTotalTime: 'Temps total moyen',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.time-to-release {
  margin-top: -16px;

  &__chart {
    width: 100%;
    height: 170px;
  }

  &__legend {
    display: flex;
    margin-bottom: 16px;
    padding-left: 0;
    overflow-x: auto;
    list-style: none;
    white-space: nowrap;

    > * + * {
      margin-left: 16px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__marker {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 50%;
    vertical-align: text-bottom;
  }

  &__label {
    color: get-color("primary", "light-3");
    font-size: 12px;
  }

  &__average {
    color: get-color("primary", "light-1");
    font-weight: $font-weight-bold;
  }
}
</style>
