<template>
  <CyKpiWidgetsWidget
    v-bind="$attrs"
    :kpi="kpi"
    :class="{ 'widget--empty': _.isEmpty(kpi.data_set) }"
    show-job
    v-on="$listeners">
    <div v-if="!_.isEmpty(kpi.data_set)">
      <!--
        The above 'wrapper' div is needed in order to prevent a Vue bug,
        which can cause scoped styles to not be attached to the div.
        See https://github.com/vuejs/vue/issues/10416
      -->
      <div class="build-history cy-scrollbars">
        <table class="build-history__table">
          <thead>
            <th>
              {{ $t('untranslated.build') }}
            </th>
            <th>{{ $t('date') }}</th>
            <th>{{ $t('duration') }}</th>
            <th class="pr-1 text-sm-right">
              {{ $t('forms.status') }}
            </th>
          </thead>
          <tr
            v-for="{ id, name, date, duration, status } of builds"
            :key="id"
            :class="['build-history__build', `build-history__build--${status}`]"
            @click="goToBuild(id)">
            <td class="build-history__name">
              #{{ name }}
            </td>
            <td>{{ date }}</td>
            <td>
              <template v-if="!['paused', 'pending', 'running'].includes(status)">
                {{ duration }}
              </template>
            </td>
            <td class="text-sm-right">
              <span class="build-history__status">{{ status }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <template v-for="(_, slot) in $slots">
      <template :slot="slot">
        <slot :name="slot"/>
      </template>
    </template>
  </CyKpiWidgetsWidget>
</template>

<script>
import CyKpiWidgetsWidget from '@/components/kpi-widgets/widget'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import 'moment-duration-format'

export default {
  name: 'CyKpiWidgetsBuildHistory',
  components: {
    CyKpiWidgetsWidget,
  },
  props: {
    kpi: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    builds: [],
  }),
  watch: {
    'kpi.data_set': {
      immediate: true,
      handler (dataSet) {
        if (_.isEmpty(dataSet)) return
        this.setDataFromSource(dataSet)
      },
    },
  },
  methods: {
    setDataFromSource ([keys, ...rows]) {
      this.builds = _.chain(rows)
        .map((row) => ({
          date: $date.$formatTimeAgo(row[keys.indexOf('date')]),
          timestamp: row[keys.indexOf('date')],
          duration: moment.duration(row[keys.indexOf('duration')]).format('mm[m] ss[s]'), // eslint-disable-line you-dont-need-momentjs/format
          name: row[keys.indexOf('name')],
          id: row[keys.indexOf('id')],
          status: row[keys.indexOf('status')],
        }))
        .orderBy(['timestamp'], ['desc'])
        .value()
    },
    goToBuild (buildId) {
      const { project_canonical: projectCanonical, pipeline_name: pipelineCanonical, job_name: jobCanonical } = this.kpi
      const { orgCanonical } = this

      this.$router.push({
        name: 'builds',
        params: {
          orgCanonical,
          projectCanonical,
          pipelineCanonical,
          jobCanonical,
          buildId,
        },
      })
    },
  },
  i18n: {
    messages: {
      en: {
        duration: 'Duration',
      },
      es: {
        duration: 'Duración',
      },
      fr: {
        duration: 'Durée',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$build-statuses: succeeded, failed, running, errored, aborted, pending, paused;
$build-border-radius: 4px;

.build-history {
  position: relative;
  height: 274px;
  margin: 0 -16px 0 -8px;
  padding-right: 8px;
  overflow-y: auto;

  &__table {
    width: 100%;
    margin-bottom: 8px;
    border-spacing: 0;

    thead {
      margin-bottom: 4px;
    }

    th {
      position: sticky;
      z-index: 2;
      top: 0;
      padding-bottom: 4px;
      background: linear-gradient(0deg, get-color("white", $alpha: 0) 0, get-color("white") 4px);
      color: get-color("primary", "light-3");
      font-size: map.get($font-sizes, "sm");
      font-weight: $font-weight-normal;
      text-align: left;

      &:first-child {
        padding-left: 22px;
      }
    }
  }

  &__build {
    position: relative;

    td {
      position: relative;
      min-height: 30px;
      padding: 4px;
      transition: background-color 0.2s ease;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: $build-border-radius;
        border-bottom-left-radius: $build-border-radius;
      }

      &:last-child {
        border-top-right-radius: $build-border-radius;
        border-bottom-right-radius: $build-border-radius;
      }
    }

    &:hover {
      td {
        background-color: get-color("primary", "light-5");
      }
    }

    @each $status in $build-statuses {
      &--#{$status} {
        $color: get-color("build", $status);

        --build-color-h: #{color.hue($color)};
        --build-color-s: #{color.saturation($color)};
        --build-color-l: #{color.lightness($color)};
        --build-color: #{color.red($color)}, #{color.green($color)}, #{color.blue($color)};
      }
    }
  }

  &__name {
    $size: 6px;

    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;

    &::before {
      content: "";
      display: inline-block;
      width: $size;
      height: $size;
      margin-right: 8px;
      margin-left: 4px;
      border-radius: 50%;
      background-color: rgb(var(--build-color));
    }
  }

  &__status {
    padding: 0 4px;
    border-radius: 3px;
    background-color:
      hsl(
        var(--build-color-h)
        var(--build-color-s)
        clamp(70%, calc(var(--build-color-l) + 25%), 90%)
      );
    color:
      hsl(
        var(--build-color-h)
        calc(var(--build-color-s) + 10%)
        min(calc(var(--build-color-l) - 35%), 20%)
      );
    font-family: $font-family-code;
  }
}

.widget:not(.widget--empty) {
  ::v-deep .widget__content {
    margin-bottom: -16px;
  }
}
</style>
