var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("activator",function(){return [_c('CyMenu',_vm._b({attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CyButton',_vm._g({attrs:{"variant":"primary","theme":"secondary","icon":"add"}},on),[_vm._v(" "+_vm._s(_vm.$t('addMetric'))+" ")])]}}]),model:{value:(_vm.show.menu),callback:function ($$v) {_vm.$set(_vm.show, "menu", $$v)},expression:"show.menu"}},'CyMenu',_vm.menuProps,false),[_c('v-list',{staticClass:"menu",attrs:{"three-line":"","dense":""}},_vm._l((_vm.$static.KPIS_SETTINGS),function(widgets,group,index){return _c('div',{key:("subheader-" + group)},[(index)?_c('v-divider',{key:("divider-" + group)}):_vm._e(),_c('v-subheader',[_vm._v(" "+_vm._s(group)+" ")]),_vm._l((widgets),function(ref){
var label = ref.label;
var legend = ref.legend;
var type = ref.type;
var widget = ref.widget;
return _c('v-list-item',{directives:[{name:"hover-siblings",rawName:"v-hover-siblings"}],key:(type + "-" + widget),on:{"click":function($event){return _vm.open(type, widget)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(label)))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$t(legend)))])],1)],1)})],2)}),0)],1)]},{"open":_vm.open}),(_vm.show.dialog)?_c('CyModal',{attrs:{"modal-type":"create","header-title":_vm.modalTitle,"close-modal-on-action-click":false,"cancel-btn-func":_vm.close,"small":""}},[_c('form',{staticClass:"add-widget-form",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('CyNotification',{attrs:{"theme":"error","content":_vm.errors}}),(_vm.kpi.error)?_c('CyNotification',{attrs:{"theme":"error","content":((_vm.$t('kpis.anErrorOccurred')) + " " + (_vm.kpi.error))}}):_vm._e(),_c('section',{staticClass:"mt-3"},[(_vm.KPISetting)?[_c('h2',{staticClass:"h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.KPISetting.label))+" ")]),_c('p',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t(_vm.KPISetting.legend))+" ")]),_c('v-text-field',{staticClass:"required-field",attrs:{"label":_vm.$t('metricName'),"required":"","error-messages":_vm.nameErrors},on:{"blur":function($event){return _vm.$v.form.name.$touch()}},model:{value:(_vm.$v.form.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.name, "$model", $$v)},expression:"$v.form.name.$model"}}),(_vm.KPISetting.extraFields.includes('env') && !_vm.KPISetting.extraFields.includes('pipeline'))?_c('v-select',{staticClass:"required-field",attrs:{"items":_vm.envs,"label":_vm.$t('forms.fieldOrgEnv'),"item-value":"canonical","item-text":"name","required":"","error-messages":_vm.envErrors,"disabled":!_vm.isCreationMode,"menu-props":{ offsetY: true }},on:{"blur":function($event){return _vm.$v.form.environmentCanonical.$touch()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var ref_item = ref.item;
var canonical = ref_item.canonical;
var icon = ref_item.icon;
var color = ref_item.color;
return [_c('span',{staticClass:"d-flex align-center"},[_c('CyAvatar',{staticClass:"mr-1",attrs:{"item":{ canonical: canonical, icon: icon, color: color },"disabled":!_vm.isCreationMode,"sm":""}}),_vm._v(" "+_vm._s(canonical)+" ")],1)]}},{key:"item",fn:function(ref){
var ref_item = ref.item;
var canonical = ref_item.canonical;
var icon = ref_item.icon;
var color = ref_item.color;
return [_c('span',{staticClass:"d-flex align-center"},[_c('CyAvatar',{staticClass:"mr-1",attrs:{"item":{ canonical: canonical, icon: icon, color: color },"sm":""}}),_vm._v(" "+_vm._s(canonical)+" ")],1)]}}],null,false,792540235),model:{value:(_vm.$v.form.environmentCanonical.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.environmentCanonical, "$model", $$v)},expression:"$v.form.environmentCanonical.$model"}}):_vm._e(),(_vm.KPISetting.extraFields.includes('pipeline'))?_c('v-select',{staticClass:"required-field",attrs:{"items":_vm.pipelines,"item-value":"id","item-text":"name","return-object":"","label":_vm.$t('untranslated.pipeline'),"required":"","error-messages":_vm.pipelineErrors,"disabled":!_vm.isCreationMode,"menu-props":{ offsetY: true }},on:{"blur":function($event){return _vm.$v.form.pipelineName.$touch()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var ref_item = ref.item;
var environment = ref_item.environment;
var paused = ref_item.paused;
var name = ref_item.name;
return [_c('span',{staticClass:"pipeline-name d-flex align-center"},[_c('CyAvatar',{staticClass:"mr-1",attrs:{"item":environment,"disabled":!_vm.isCreationMode,"sm":""}}),_c('span',{staticClass:"pipeline-name__text"},[_vm._v(" "+_vm._s(name)+" ")]),(paused)?_c('CyTag',{staticClass:"ml-1",attrs:{"small":"","variant":"default"}},[_vm._v(" "+_vm._s(_vm.$t('pipeline.paused'))+" ")]):_vm._e()],1)]}},{key:"item",fn:function(ref){
var ref_item = ref.item;
var environment = ref_item.environment;
var paused = ref_item.paused;
var name = ref_item.name;
return [_c('span',{staticClass:"pipeline-name d-flex align-center"},[_c('CyAvatar',{staticClass:"mr-1",attrs:{"item":environment,"sm":""}}),_c('span',{staticClass:"pipeline-name__text"},[_vm._v(" "+_vm._s(name)+" ")]),(paused)?_c('CyTag',{staticClass:"ml-1",attrs:{"small":"","variant":"default"}},[_vm._v(" "+_vm._s(_vm.$t('pipeline.paused'))+" ")]):_vm._e()],1)]}}],null,false,2203927339),model:{value:(_vm.pipeline),callback:function ($$v) {_vm.pipeline=$$v},expression:"pipeline"}}):_vm._e(),(_vm.KPISetting.extraFields.includes('job'))?_c('v-select',{staticClass:"required-field",attrs:{"items":_vm.jobs,"item-value":"name","label":_vm.$t('untranslated.job'),"loading":_vm.loading.jobs,"required":"","error-messages":_vm.jobErrors,"disabled":!_vm.isCreationMode,"menu-props":{ offsetY: true }},on:{"blur":function($event){return _vm.$v.form.jobName.$touch()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var name = ref.item.name;
return [_vm._v(" "+_vm._s(name)+" ")]}},{key:"item",fn:function(ref){
var name = ref.item.name;
return [_vm._v(" "+_vm._s(name)+" ")]}}],null,false,2490020988),model:{value:(_vm.$v.form.jobName.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.jobName, "$model", $$v)},expression:"$v.form.jobName.$model"}}):_vm._e(),(_vm.KPISetting.extraFields.includes('env_order'))?_c('v-input',{staticClass:"env-input required-field",attrs:{"label":_vm.$t('envOrder'),"hint":_vm.$t('envOrderHint'),"error-messages":_vm.envOrderErrors,"persistent-hint":"","hide-details":"","required":""}},[_c('div',{staticClass:"draggable"},[(_vm.$v.form.envOrder.$model.length)?_c('draggable',{model:{value:(_vm.$v.form.envOrder.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.envOrder, "$model", $$v)},expression:"$v.form.envOrder.$model"}},_vm._l((_vm.$v.form.envOrder.$model),function(env,index){return _c('div',{key:index,attrs:{"test":index}},[_c('v-chip',{staticClass:"mb-1",attrs:{"close":"","outlined":""},on:{"click:close":function($event){return _vm.removeEnv(index)}}},[_c('v-icon',{staticClass:"draggable__handle",attrs:{"size":"20"}},[_vm._v(" drag_indicator ")]),_c('CyAvatar',{staticClass:"mx-1",attrs:{"item":_vm.getEnv(env),"sm":""}}),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(env)+" ")])],1)],1)}),0):_c('div',{staticClass:"draggable__empty"},[_vm._v(" "+_vm._s(_vm.$t('envOrderEmpty'))+" ")]),_c('CyMenu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('CyButton',_vm._g({staticClass:"mt-3",attrs:{"icon":"add","variant":"secondary","theme":"primary","sm":"","disabled":!_vm.availableEnvs.length}},on),[_vm._v(" "+_vm._s(_vm.$t('btnAddEnv'))+" ")])]}}],null,false,2732314171)},[_c('v-list',_vm._l((_vm.availableEnvs),function(env){return _c('v-list-item',{key:env.canonical,on:{"click":function($event){return _vm.addEnv(env)}}},[_c('v-list-item-avatar',[_c('CyAvatar',{staticClass:"mr-1",attrs:{"item":env,"sm":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(env.canonical))])],1)],1)}),1)],1)],1)]):_vm._e()]:_vm._e()],2)],1),_c('template',{slot:"modal-buttons"},[(!_vm.isCreationMode)?_c('CyButton',{staticClass:"mr-auto",attrs:{"disabled":!_vm.$cycloid.permissions.canDisplay('DeleteKPI', _vm.kpi.canonical),"variant":"tertiary","theme":"error","loading":_vm.loading.remove},on:{"click":_vm.remove}},[_c('v-icon',[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.$t('forms.btnDelete'))+" ")],1):_vm._e(),_c('CyButton',{attrs:{"theme":"secondary","variant":"secondary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("close")]),_vm._v(" "+_vm._s(_vm.$t('forms.btnClose'))+" ")],1),_c('CyButton',{staticClass:"ml-4",attrs:{"disabled":!_vm.canSave,"theme":"secondary","loading":_vm.loading.save},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("check")]),_vm._v(" "+_vm._s(_vm.confirmBtnText)+" ")],1)],1)],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }